import React, { useState, useRef, useEffect } from 'react'
import { Box, Stack } from '@mui/system'
import Input from '../../Inputs/Input'
import { useStyles } from './LetsMeetFormStyle'
import DatePicker from '../../Inputs/DatePicker'
import Button from '../../Button/Button'
import InputLabel from '@mui/material/InputLabel'
import LaterTooltip from '../../Tooltips/LaterTooltip/LaterTooltip'
import Select from '../../Inputs/Select'
import { Typography } from '@mui/material'
import LocalStorageShedule from '../../../Utils/LocalStorageShedule'
import LocalStorageService from '../../../Utils/LocalStoreServices'
import ClearIcon from '@mui/icons-material/Clear'
//APIS END POINTS
import { letsMeet } from '../../../Store/Actions/LetsMeet'
import { UserValidate } from '../../../Store/Actions/UserValidate'
import { validSsn } from '../../../Store/Actions/ValidSsn'
// import { customerAddressValidate } from "../../../Store/Actions/CustomerAddressValidate";
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { Formik } from 'formik'
import InputMask from 'react-input-mask'
import clsx from 'clsx'
import Checkbox from '../../Button/CheckBox'
import DownloadIcon from '@mui/icons-material/Download'
import { useNavigate, Outlet, useLocation } from 'react-router-dom'
import proofOccupancyFileLink from '../../../assets/pdf/ProofofOccupancy.pdf'
import AlreadyCustomerContainer from '../../AlreadyCustomer/AlreadyCustomerContainer'
import NotCustomerYetContainer from '../../NotCustomerYet/NotCustomerYetContainer'
import axios from 'axios'
import Actions from '../../../Store/Types'
import { baseUrl } from '../../../baseUrl'
import IsUservalid from '../../../Utils/IsUservalid'

const LetsMeetForm = () => {
  const Navigate = useNavigate()
  const dispatch = useDispatch()
  const Location = useLocation()
  const [open, setOpen] = useState(false)
  const [fileError, setFileError] = useState({
    messageSSN: '',
    messageProof: '',
  })
  const [customerAddresData, setCustomerAddressData] = useState({
    matchingStatus: 0,
  })
  const [hideFields, setHideFields] = useState({
    display_fields: '',
    display_already_customer: 'none',
    display_not_customer: 'none',
    display_validatio_address: 'none',
  })
  const [user, setUser] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    ssn: '',
  })

  const [buttonActive, setButtonActive] = useState({
    buttonLater1: false,
    buttonLater2: false,
    now1: false,
    now2: false,
  })
  useEffect(() => {
    window.onpopstate = (e) => {
      window.history.pushState(null, '')
    }
    window.history.pushState(null, '')
  }, [])

  const handleButtonActive1 = () => {
    setupload('')
    setButtonActive({ ...buttonActive, buttonLater1: true, now1: false })
    setB4Data({ ...b64Data, proofOfSsnImg: '' })
    inputFile.current.files = null
  }

  const handleButtonActive2 = () => {
    setButtonActive({ ...buttonActive, buttonLater2: true, now2: false })
    setFileName('')
    setB4Data({ ...b64Data, proofOfAddressImg: '' })
  }

  const [cityOption, setCityOption] = useState('')
  const [isAValidAddress, setIsAValidAddress] = useState(false)

  useEffect(() => {
    if (cityOption !== '') {
      handlerCustomerAddres()
    }
  }, [cityOption])

  const referenceStatus = useSelector((state) => state.ReferenceStatusReducer)

  useEffect(() => {
    const customer = LocalStorageShedule.getService()
    customer.setCustomer({
      firstName: user.firstName,
      middleName: user.middleName,
    })
  }, [user])

  const [statusForm, setStatusForm] = useState(false)

  useEffect(() => {
    const statusToken = LocalStorageService.getAccessToken()
    if (statusToken ?? false) {
      setStatusForm(false)
      return Navigate('/NonCustomerPage/yourLoanRequest')
    } else setStatusForm(true)
  }, [])

  const [progress, setProgress] = useState({
    field_SSN: true,
    field_file_ssn: true,
    field_phone: false,
    field_email: false,
    field_permanent_addres: false,
    field_district: true,
    field_city: true,
    field_file_proof: false,
    field_checkbox: true,
    field_education: false,
    field_home_status: false,
  })

  const handlerField = (values) => {
    // const {firstName, lastName, dateOfBirth, ssn, phone, emailAddress, districtId, urbanAreaId, educationId, homeStatusId} = values;
    const {
      firstName,
      lastName,
      dateOfBirth,
      ssn,
      phone,
      emailAddress,
      districtId,
      urbanAreaId,
      educationId,
      homeStatusId,
    } = values
    if (
      lengthField(firstName) &&
      lengthField(lastName) &&
      lengthField(toString(dateOfBirth?._i))
    ) {
      setProgress({ ...progress, field_SSN: false })
    } else {
      setProgress({ ...progress, field_SSN: true })
    }
  }

  const lengthField = (field = '') => field.length >= 2

  const success = useSelector((state) => state.LetsMeetFormReducer.success)

  useEffect(() => {
    // console.log(success)
    if (success == true) Navigate('/NonCustomerPage/letsMeet/opt')
  }, [success])

  const accountMatch = useSelector(
    (state) => state.LetsMeetFormReducer.accountMatchingStatus
  )
  const [fileName, setFileName] = useState('')
  const [upload, setupload] = useState('')
  const [ssnStatus, setSsnStatus] = useState(9)
  const [b64Data, setB4Data] = useState({
    proofOfSsnImg: '',
    proofOfAddressImg: '',
    // proofOfOccupancyImg: "",`
  })

  useEffect(() => {
    dispatch({ type: Actions.PROOF, payload: b64Data })
  }, [b64Data])

  const classes = useStyles()

  const inputFile = useRef(null)
  const uploadfile = useRef(null)

  const onButtonClick = () => {
    inputFile.current.click()
  }
  const Click = () => {
    uploadfile.current.click()
  }

  // UPLOAD SOCIAL SECURITY CARD
  const File = async (e) => {
    const LIMIT_FILE = 2000
    const file = e.target.files[0]
    const { FORMAT_FILE, SIZE_FILE } = dataFile(file)
    if (isValidFormatFile(FORMAT_FILE) && SIZE_FILE <= LIMIT_FILE) {
      setFileError({ ...fileError, messageSSN: '' })
      setButtonActive({ ...buttonActive, now1: true, buttonLater1: false })
      setupload(file.name)
      const base64 = await convertBase64(file)
      const proofOfSsnImg = base64
      setB4Data({
        ...b64Data,
        proofOfSsnImg,
      })
      e.target.value = ''
    } else {
      setFileError({
        ...fileError,
        messageSSN: 'The file cannot exceed 2MB',
      })
      setB4Data({ ...b64Data, proofOfSsnImg: '' })
    }
  }

  // VALIDATION ADDRESS proofOfAddressImg
  const FileName = async (e) => {
    const LIMIT_FILE = 2000
    const file = e.target.files[0]
    const { FORMAT_FILE, SIZE_FILE } = dataFile(file)

    if (isValidFormatFile(FORMAT_FILE) && SIZE_FILE <= LIMIT_FILE) {
      setFileError({ ...fileError, messageProof: '' })
      setButtonActive({ ...buttonActive, now2: true, buttonLater2: false })
      setFileName(file.name)
      const base64 = await convertBase64(file)
      // If open is === true send PDF if not send img
      let proofOfOccupancyImg = '' // PDF
      let proofOfAddressImg = '' // IMG
      if (open) proofOfOccupancyImg = base64
      else proofOfAddressImg = base64
      setB4Data({
        ...b64Data,
        proofOfOccupancyImg,
        proofOfAddressImg,
      })
      e.target.value = ''
    } else {
      setFileError({
        ...fileError,
        messageProof: 'The file cannot exceed 2MB',
      })
      setB4Data({ ...b64Data, proofOfAddressImg: '', proofOfOccupancyImg: '' })
    }
  }

  const dataFile = (file = { size: 0, type: 'mp4' }) => {
    const { size, type } = file
    const FORMAT_FILE = type.split('/')[1]
    const SIZE_FILE = parseInt(size / 1024)
    return { FORMAT_FILE, SIZE_FILE }
  }

  const isValidFormatFile = (format_file) => {
    return ['pdf', 'jpeg', 'png'].includes(format_file)
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const calculateAge = (birthday) => {
    let ageDifMs = Date.now() - birthday
    let ageDate = new Date(ageDifMs)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  const [errorDate, setErrorDate] = useState('')

  const handleDatValidate = (e) => {
    const ageCustomer = calculateAge(new Date(e)) > 18
    if (!ageCustomer) setErrorDate('You must be 18 years or older')
    else setErrorDate('')
  }

  const validationSchema = yup.object({
    firstName: yup.string().required('**Please enter your First Name'),
    middleName: yup.string(),
    lastName: yup.string().required('**Please enter your Last Name'),
    dateOfBirth: yup
      .date()
      .nullable()
      .test('dateOfBirth', 'You must be 18 years or older', (value) => {
        if (value != null) {
          if (calculateAge(new Date(value)) > 18) {
            setErrorDate('')
            return true
          } else {
            setErrorDate('You must be 18 years or older')
            return false
          }
        }
        return true
      })
      .transform((value) => {
        if (value == 'Invalid Date') return null
        else return value
      })
      .required('**Please Provide your date of birth'),
    ssn: yup
    .string()
    .test("SOCIAL SECURITY NUMBER*", "SSN must have 9 digits", function (value) {
      // console.log(String(value).replace(/[_\s]/g, '').length)
      if (value) {
        return String(value).replace(/[_\s]/g, '').length === 9;
      } else {
        return false;
      }
    })
    .required('**Please enter your ssn number '),
    emailAddress: yup
      .string('Email should be string')
      .email('Please provide a valid email address')
      .required('**Email is Required'),
    confirmEmailAdress: yup
      .string('Email should be string')
      .oneOf([yup.ref('emailAddress')], '**Email address must match')
      .required('**Email is Required'),
    phone: yup.string().required('**Please enter your Phone number'),
    address: yup.string('').required('**Please provide your address'),
    districtId: yup
      .object('')
      .required('**Please Select your District')
      .nullable(),
    urbanAreaId: yup
      .object('')
      .required('**Please Select your City/town/village')
      .nullable(),
    educationId: yup
      .object('')
      .required('**Please Select your Education')
      .nullable(),
    homeStatusId: yup
      .object('')
      .required('**Please Select your Home status')
      .nullable(),
  })

  const handleFormSubmit = (values) => {
    const districtId = values.districtId.id
    const urbanAreaId = values.urbanAreaId.id
    const educationId = values.educationId.id
    const homeStatusId = values.homeStatusId.id
    const ssn = values.ssn.toString()
    const proofOfAddressImg = b64Data.proofOfAddressImg
    const validAddress = isAValidAddress
    // const proofOfOccupancyImg = b64Data.proofOfOccupancyImg;
    const proofOfSsnImg = b64Data.proofOfSsnImg

    // delete values.districtId
    // delete values.urbanAreaId
    // delete values.educationId
    // delete values.homeStatusId
    // delete values.confirmEmailAdress

    values.districtId = districtId
    values.urbanAreaId = urbanAreaId
    values.educationId = educationId
    values.homeStatusId = homeStatusId
    values.ssn = ssn.replace(/ /g, '')
    values.proofOfAddressImg = proofOfAddressImg
    values.isAValidAddress = validAddress
    // values.proofOfOccupancyImg = proofOfOccupancyImg;
    values.proofOfSsnImg = proofOfSsnImg
    dispatch(letsMeet({ ...values }))
  }

  const handlerUserExist = () => {
    const values = {
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      dateOfBirth: user.dateOfBirth,
      ssbID: user.ssn,
    }

    const url = `${baseUrl}/customers/validation/ssn`
    axios
      .post(url, { ...values })
      .then(({ data }) => {
        dispatch({ type: Actions.SSNEXISTS, payload: data.matchingStatus })
        if (data.matchingStatus === 1) {
          setHideFields({
            ...hideFields,
            display_fields: 'none',
            display_already_customer: '',
            display_not_customer: 'none',
          })
        }
        if (data.matchingStatus === 5) {
          setHideFields({
            ...hideFields,
            display_fields: 'none',
            display_already_customer: 'none',
            display_not_customer: '',
          })
          dispatch({ type: Actions.LETSMEETFORM, payload: values })
        }
        if (data.matchingStatus === 9) {
          setHideFields({
            ...hideFields,
            display_fields: '',
            display_already_customer: 'none',
            display_not_customer: 'none',
          })
        }
      })
      .catch((e) => {})
  }

  const handlerCustomerAddres = () => {
    const data = {
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      address1: user.address,
      district: user.districtId.name,
      cityTown: cityOption.name || '',
    }

    CustumerAddres({ ...data })
    // dispatch(customerAddressValidate({ ...data }));
  }

  const CustumerAddres = (data) => {
    const url = `${baseUrl}/customers/address/validation`
    axios
      .post(url, data)
      .then(({ data }) => {
        if (data.matchingStatus === 9) {
          setHideFields({ ...hideFields, display_validatio_address: '' })
          setIsAValidAddress(false)
        }
        if (data.matchingStatus === 0) {
          setHideFields({ ...hideFields, display_validatio_address: 'none' })
          setIsAValidAddress(true)
        }
      })
      .catch((e) => {
        // setHideFields({ ...hideFields, display_validatio_address: "" })
      })
  }

  const initialValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    emailAddress: '',
    confirmEmailAdress: '',
    dateOfBirth: null,
    phone: '',
    ssn: '',
    address: '',
    educationId: '',
    districtId: '',
    urbanAreaId: '',
    homeStatusId: '',
  }

  return (
    <Box className={classes.FormsContainer}>
      {Location.pathname === '/NonCustomerPage/letsMeet/opt' ? (
        <Stack mb={10} mt={10}>
          <Outlet />
        </Stack>
      ) : (
        <Box>
          <Typography variant="h6" className={classes.FormTitle}>
            PLEASE COMPLETE THE INFORMATION BELOW
          </Typography>
          <Typography variant="subtitle1" className={classes.SubTitle}>
            "*" Mandatory Field
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => handleFormSubmit(values)}
            validationSchema={validationSchema}
            validate={handlerField}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <form>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 2 }}
                  >
                    <Input
                      Lable="FIRST NAME*"
                      name="firstName"
                      value={values.firstName}
                      onChange={(e) => {
                        setFieldValue('firstName', e.target.value)
                        setFieldValue('ssn', '')
                      }}
                      error={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                    />
                    <Input
                      Lable="MIDDLE NAME"
                      value={values.middleName}
                      name="middleName"
                      onBlur={handleBlur}
                      error={touched.middleName && errors.middleName}
                      onChange={(e) => {
                        setFieldValue('middleName', e.target.value)
                        setFieldValue('ssn', '')
                      }}
                    />
                  </Stack>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 2 }}
                    mt={3}
                  >
                    <Input
                      Lable="LAST NAME*"
                      value={values.lastName}
                      name="lastName"
                      onBlur={handleBlur}
                      error={touched.lastName && errors.lastName}
                      onChange={(e) => {
                        setFieldValue('lastName', e.target.value)
                        setFieldValue('ssn', '')
                      }}
                    />
                    <DatePicker
                      Label="DATE OF BIRTH*"
                      value={values.dateOfBirth}
                      name="dateOfBirth"
                      onBlur={handleBlur}
                      disableDay={false}
                      onChange={(e) => {
                        setFieldValue('dateOfBirth', e)
                        setFieldValue('ssn', '')
                      }}
                      Title={
                        (touched.dateOfBirth && errors.dateOfBirth) || errorDate
                      }
                      error={
                        (touched.dateOfBirth && errors.dateOfBirth) || errorDate
                      }
                      disableFuture={true}
                    />
                  </Stack>
                  <Stack
                    direction={{ xs: 'column', sm: 'column', md: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 2 }}
                    mt={6}
                  >
                    <InputMask
                      mask="999 999 999"
                      alwaysShowMask={false}
                      name="ssn"
                      disabled={progress.field_SSN}
                      value={values.ssn}
                      onBlur={handlerUserExist}
                      onChange={(e) => {
                        setFieldValue('ssn', e.target.value)
                        setUser({
                          ...values,
                          ssn: e.target.value.replace(/ /g, ''),
                        })
                      }}
                    >
                      {(inputProps) => (
                        <Input
                          Lable="SOCIAL SECURITY NUMBER*"
                          error={touched.ssn && errors.ssn}
                          {...inputProps}
                          type="text"
                        />
                      )}
                    </InputMask>
                    <Stack direction={{ xs: 'column', sm: 'column', md: "column" }}>
                      <InputLabel shrink htmlFor="bootstrap-input">
                        UPLOAD SOCIAL SECURITY CARD
                      </InputLabel>
                      <Button
                        Title="Now"
                        variant="h7"
                        className={`${classes.UploadNOwBtn} ${
                          buttonActive.now1 && classes.ActiveButton
                        }`}
                        onClick={onButtonClick}
                      />
                      <input
                        type="file"
                        id="file"
                        accept="application/pdf,image/*"
                        onChange={(e) => File(e)}
                        ref={inputFile}
                        style={{ display: 'none' }}
                        disabled={progress.field_SSN}
                      />
                      {fileError.messageSSN !== '' ? (
                        <p className={classes.fileTextErr}>
                          {fileError.messageSSN}
                        </p>
                      ) : (
                        <p className={classes.fileText}>
                          {`${String(upload).substring(0, 20)} ...`}
                          <span>
                            {upload && (
                              <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  setB4Data({ ...b64Data, proofOfSsnImg: '' })
                                  setupload('')
                                }}
                              />
                            )}
                          </span>
                        </p>
                      )}
                    </Stack>
                    <Button
                      Tooltiptitle={<LaterTooltip />}
                      Title="Later"
                      variant="h7"
                      className={`${classes.LaterButton} ${
                        buttonActive.buttonLater1 && classes.ActiveButton
                      }`}
                      BtnVariant="contained"
                      onClick={handleButtonActive1}
                      // backgroundColor="#F07F3C"
                    />
                  </Stack>
                  <div style={{ display: hideFields.display_fields }}>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 2 }}
                      mt={6}
                    >
                      <InputMask
                        mask="501-699-9999"
                        alwaysShowMask={false}
                        disabled={progress.field_phone}
                        name="phone"
                        value={values.phone}
                        onChange={(e) => setFieldValue('phone', e.target.value)}
                      >
                        {(inputProps) => (
                          <Input
                            Lable="MOBILE NUMBER*"
                            error={touched.phone && errors.phone}
                            {...inputProps}
                            type="tel"
                          />
                        )}
                      </InputMask>
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 2 }}
                      mt={3}
                    >
                      <Input
                        Lable="EMAIL ADDRESS*"
                        placeholder="Enter email address"
                        name="emailAddress"
                        autoComplete="off"
                        disabled={progress.field_email}
                        error={touched.emailAddress && errors.emailAddress}
                        value={values.emailAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Input
                        placeholder="Confirm email address"
                        value={values.confirmEmailAdress}
                        name="confirmEmailAdress"
                        autoComplete="off"
                        onBlur={handleBlur}
                        disabled={progress.field_email}
                        error={
                          touched.confirmEmailAdress &&
                          errors.confirmEmailAdress
                        }
                        onChange={handleChange}
                        Title="Email does not match"
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 2 }}
                      // alignItems="start"
                      mt={3}
                      style={{ display: '' }}
                    >
                      <Input
                        Lable="PERMANENT ADDRESS*"
                        type="select"
                        placeholder="Address"
                        value={values.address}
                        name="address"
                        onChange={handleChange}
                        onBlur={(e, value) => {
                          setProgress({ ...progress, field_district: false })
                        }}
                        error={touched.address && errors.address}
                        disabled={progress.field_permanent_addres}
                      />
                      <Select
                        placeholder="District"
                        value={values.districtId}
                        name="districtId"
                        onChange={(e, value) => {
                          value && setFieldValue('districtId', value)
                          setFieldValue('urbanAreaId', '')
                          value &&
                            setProgress({ ...progress, field_city: false })
                        }}
                        ApiUrl={`${baseUrl}/districts`}
                        error={touched.districtId && errors.districtId}
                        disabled={progress.field_district}
                        className={classes.SelectAddres}
                      />
                      <Select
                        placeholder="City/town/village"
                        ApiUrl={`${baseUrl}/districts/${values.districtId.id}/urban-areas`}
                        value={values.urbanAreaId}
                        name="urbanAreaId"
                        id="urbanAreaId"
                        error={touched.urbanAreaId && errors.urbanAreaId}
                        onChange={(e, value) => {
                          value && setFieldValue('urbanAreaId', value)
                          setUser({ ...values })
                          setCityOption(value)
                        }}
                        // onBlur={handlerCustomerAddres}
                        disabled={progress.field_city}
                        className={classes.SelectAddres}
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 2 }}
                      mt={3}
                      alignItems="start"
                      style={{ display: hideFields.display_validatio_address }}
                    >
                      <Box className={classes.UploadNowWrapper}>
                        <InputLabel
                          shrink
                          htmlFor="bootstrap-input"
                          className={classes.InputLable}
                        >
                          PROOF OF ADDRESS / OCCUPANCY (Valid: Utility Bill,
                          Driver's License)
                        </InputLabel>
                        <Button
                          Title="Now"
                          variant="h7"
                          onClick={Click}
                          // className={clsx(
                          //   classes.UploadNOwBtn,
                          //   classes.UploadNOwBtnTwo
                          // )}
                          className={`${classes.UploadNOwBtn} ${
                            classes.UploadNOwBtnTwo
                          } ${buttonActive.now2 && classes.ActiveButton}`}
                          BtnVariant="outlined"
                        />
                        <input
                          type="file"
                          id="file"
                          accept="application/pdf,image/*"
                          onChange={(e) => FileName(e)}
                          ref={uploadfile}
                          style={{ display: 'none' }}
                          disabled={progress.field_file_proof}
                        />
                        {fileError.messageProof !== '' ? (
                          <p className={classes.fileTextErr}>
                            {fileError.messageProof}
                          </p>
                        ) : (
                          <p className={classes.fileText}>
                            {' '}
                            {`${String(fileName).substring(0, 20)} ...`}
                            <span>
                              {fileName && (
                                <ClearIcon
                                  className={classes.deleteFileIcon}
                                  onClick={() => {
                                    setB4Data({
                                      ...b64Data,
                                      proofOfAddressImg: '',
                                    })
                                    setFileName('')
                                  }}
                                />
                              )}
                            </span>
                          </p>
                        )}
                      </Box>
                      {open === true ? (
                        <a
                          href={proofOccupancyFileLink}
                          className={classes.Download}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <Button
                            Title="Download the proof of occupancy template"
                            variant="h7"
                            className={classes.Download}
                            BtnVariant="outlined"
                            endIcon={<DownloadIcon />}
                          />
                        </a>
                      ) : (
                        ''
                      )}
                      <Button
                        Tooltiptitle={<LaterTooltip />}
                        Title="Later"
                        variant="h7"
                        // className={clsx(
                        //   classes.LaterButton,
                        //   classes.Laterbuttontwo
                        // )}
                        className={`${classes.LaterButton} ${
                          buttonActive.buttonLater2 && classes.ActiveButton
                        }`}
                        BtnVariant="contained"
                        onClick={handleButtonActive2}
                        backgroundColor="#F07F3C"
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 2 }}
                      mt={3}
                      style={{ display: hideFields.display_validatio_address }}
                    >
                      <Checkbox onChange={(e) => setOpen(e.target.checked)} />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 2 }}
                      mt={3}
                    >
                      <Select
                        Label="EDUCATION*"
                        ApiUrl={`${baseUrl}/educations`}
                        placeholder="Select your level of education"
                        className={classes.Lable}
                        value={values.educationId}
                        error={touched.educationId && errors.educationId}
                        onChange={(e, value) =>
                          value && setFieldValue('educationId', value)
                        }
                        name="educationId"
                        disabled={progress.field_education}
                      />
                      <Select
                        ApiUrl={`${baseUrl}/home-statuses`}
                        Label="HOME STATUS*"
                        className={classes.Lable}
                        placeholder="Select Home Status"
                        error={touched.homeStatusId && errors.homeStatusId}
                        value={values.homeStatusId}
                        name="homeStatusId"
                        onChange={(e, value) =>
                          value && setFieldValue('homeStatusId', value)
                        }
                        disabled={progress.field_home_status}
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 2 }}
                      justifyContent="center"
                      alignItems="center"
                      mt={15}
                      mb={10}
                    >
                      <Button
                        Title="NEXT"
                        variant="h7"
                        className={classes.NextButton}
                        BtnVariant="contained"
                        onClick={handleSubmit}
                      />
                    </Stack>
                  </div>
                  <div style={{ display: hideFields.display_already_customer }}>
                    <AlreadyCustomerContainer />
                  </div>
                  <div style={{ display: hideFields.display_not_customer }}>
                    <NotCustomerYetContainer setHideFields={setHideFields} />
                  </div>
                </form>
              )
            }}
          </Formik>
        </Box>
      )}
    </Box>
  )
}

export default LetsMeetForm

import { Interceptor } from "../../../Utils/Interceptor";
const AxiosInterceptor = Interceptor();

const convertToUpload = (theBlob,filename) => {
  if(theBlob != null){
    var arr = theBlob.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    return new File([u8arr], filename, { type: mime });
  }else{
    return ""
  }
};
const getFinancialInstitution = async (bankId) => {
  if(bankId != 0){
    const response = await AxiosInterceptor.get(
      `/banks/${bankId}?filter={ "order": "position"}`
    )
    return response.data;
  }else{
    return ""
  }
};
const mapLoanPayments = async () => {
  const response = await AxiosInterceptor.get(
    "/monthly-credit-obligations/Loan Payments"
  );
  let data;
  if (response.data.length != 0) {
    data = await Promise.all(
      response.data.map(async (payment, index) => {
        const bank = await getFinancialInstitution(payment.bankId);
        const file = convertToUpload(payment.statementFile,payment.statementFileName);
        return {
          id: payment.id,
          bankId: bank,
          file: file,
          amount: parseFloat(payment.amount),
          customerId: payment.customerId
        };
      })
    );
  } else {
    data = [{ id: 0, bankId: "", file: null, amount: '0' }];
  }
  return data;
};
const mapMorageOrRent = async () => {
  const response = await AxiosInterceptor.get(
    "/monthly-credit-obligations/Mortgage or rent"
  );
  let data;
  if (response.data.length != 0) {
    data = await Promise.all(
      response.data.map(async (payment, index) => {
        const bank = await getFinancialInstitution(payment.bankId);
        const file = convertToUpload(payment.statementFile,payment.statementFileName);
        return {
          id: payment.id,
          bankId: bank,
          file: file,
          amount: parseFloat(payment.amount),
          customerId: payment.customerId
        };
      })
    );
  } else {
    data = [{ id: 0, bankId: "", file: null, amount: '0' }];
  }
  return data;
};
const mapcreditCard = async () => {
  const response = await AxiosInterceptor.get(
    "/monthly-credit-obligations/Credit Card"
  );
  let data;
  if (response.data.length != 0) {
    data = await Promise.all(
      response.data.map(async (payment, index) => {
        const bank = await getFinancialInstitution(payment.bankId);
        const file = convertToUpload(payment.statementFile,payment.statementFileName);
        return {
          id: payment.id,
          bankId: bank,
          file: file,
          amount: parseFloat(payment.amount),
          customerId: payment.customerId
        };
      })
    );
  } else {
    data = [{ id: 0, bankId: "", file: null, amount: '0' }];
  }
  return data;
};
const mapoverDraft = async () => {
  const response = await AxiosInterceptor.get(
    "/monthly-credit-obligations/Overdraft"
  );
  let data;
  if (response.data.length != 0) {
    data = await Promise.all(
      response.data.map(async (payment, index) => {
        const bank = await getFinancialInstitution(payment.bankId);
        const file = convertToUpload(payment.statementFile,payment.statementFileName);
        return {
          id: payment.id,
          bankId: bank,
          file: file,
          amount: parseFloat(payment.amount),
          customerId: payment.customerId
        };
      })
    );
  } else {
    data = [{ id: 0, bankId: "", file: null, amount: '0' }];
  }
  return data;
};
const mapOther = async () => {
  const response = await AxiosInterceptor.get(
    "/monthly-credit-obligations/Other"
  );
  let data;
  if (response.data.length != 0) {
    data = await Promise.all(
      response.data.map(async (payment, index) => {
        const bank = await getFinancialInstitution(payment.bankId);
        const file = convertToUpload(payment.statementFile,payment.statementFileName);
        return {
          id: payment.id,
          bankId: bank,
          file: file,
          amount: parseFloat(payment.amount),
          customerId: payment.customerId
        };
      })
    );
  } else {
    data = [{ id: 0, bankId: "", file: null, amount: '0' }];
  }
  return data;
};

const mapRent = async () => {
  const response = await AxiosInterceptor.get(
    "/monthly-credit-obligations/Rent"
  );
  let data;
  if (response.data.length !== 0) {
    data = await Promise.all(
      response.data.map(async (payment, index) => {
        return {
          id: payment.id,
          amount: parseFloat(payment.amount),
          customerId: payment.customerId
        };
      })
    );
  } else {
    data = [{ id: 0, amount: '0' }];
  }
  return data;
};

const template = {
  amount: '0',
  bankId: "",
  file: null,
};
export const defaultValues ={
  loanPayments: [
    {
      ...template,
    },
  ],
  morageOrRent: [
    {
      ...template,
    },
    
  ],
  creditCard: [
    {
      ...template,
    },
    
  ],
  overDraft: [
    {
      ...template,
    },
    
  ],
  other: [
    {
      ...template,
    },
    
  ],
  rent: [
    {
      ...template
    },

  ],
}
export async function getData() {
 return Promise.all(
    [
      mapLoanPayments(),
      mapMorageOrRent(),
      mapcreditCard(),
      mapoverDraft(),
      mapOther(),
      mapRent(),
    ]
  )
}

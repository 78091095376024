import axios from "axios";
import { baseUrl } from "../../baseUrl";
import Actions from "../Types";

export function letsMeet(data) {
  return async (dispatch, getState) => {
    const url =`${baseUrl}/customers`
    dispatch({
      type: Actions.LOADING,
      payload: true,
    });
    await axios
      .post(url, data)
      .then((response) => {
        dispatch({
          type: Actions.LETSMEETFORM,
          payload: response.data,
        });
        dispatch({
          type: Actions.SUCCESS,
        });
      })
      .catch((e) => {
    
        if(e.response.status == 400)
        {
          dispatch({
            type: Actions.SNACKBARMESSAGES,
            payload: e.response.data.error.message,
          });
        }
        dispatch({
          type: Actions.USEREXSITS,
          payload: true,
        });
      })
      .finally(() => {
        dispatch({
          type: Actions.LOADING,
          payload: false,
        });
      });
  };
}

import React, { useState, useEffect } from "react";
import { useStyles } from "./YourLoanRequestStyle";
import { Box, Stack } from "@mui/system";
import LoanDetails from "../../LoanDetails/LoanDetails";
import PersonalOnlineLoan from "../../PersonalOnlineLoan/PersonalOnlineLoan";
import LoanCalculator from "../../LoanCalculator/LoanCalculator";
import ArrowButton from "../../ArrowButton/ArrowButton";
import { Outlet, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetLoandRequest } from "../../../Store/Actions/GetLoandRequest";
import { useRef } from "react";
import LocalStorageService from "../../../Utils/LocalStoreServices";
import Actions from "../../../Store/Types";
import { loanRequest } from "../../../Store/Actions/LoanRequest";

const YourLoanRequest = () => {
  const {
    requestedAmount,
    requestedTerms,
    amount: monto,
    terms: terminos,
  } = useSelector((state) => state.LoanRequestReducer);
  const { dataSchedule } = useSelector((state) => state.ScheduleRequestReducer);

  const Location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const Navigate = useNavigate();
  const [amount, setAmount] = useState();
  const [terms, setTerms] = useState();
  const [error, setError] = useState("");
  const [errorTerms, setErrorTerms] = useState("");
  const [nextLink, setNextLink] = useState("#");
  const [showButtonLink, setShowButtonLink] = useState(true);

  const countPage = useRef(0);

  useEffect(() => {
    const formData = {
      requestedAmount: Number(amount),
      requestedTerms: Number(terms),
      // promoCode: promoCodeValue,
      interestRate: 1,
      debtCancellationSchemeRequested: false,
    }
    if (amount > 30000 && terms >= 12) {
      dispatch(loanRequest(formData))
    }

    if (amount >= 1000 && terms > 60) {
      dispatch(loanRequest(formData))
    }
  }, [terms, amount])

  useEffect(() => {
    window.onpopstate = (e) => {
      window.history.pushState(null, "");
    };
    window.history.pushState(null, "");
  }, []);

  useEffect(() => {
    if (dataSchedule.emailSent ?? false) {
      setShowButtonLink(false);
      LocalStorageService.clearToken();
    }
  }, [dataSchedule]);

  useEffect(() => {
    if (requestedAmount > 30_000 || requestedTerms > 60) {
      setShowButtonLink(false)
    } else setShowButtonLink(true)
  }, [requestedAmount, requestedTerms])

  useEffect(() => {
    if (countPage.current === 0 && requestedAmount > 0 && requestedTerms > 60) {
      setNextLink("/NonCustomerPage/monthlyIncome");
      return;
    }
    if (amount > 0 && terms > 0) setNextLink("/NonCustomerPage/monthlyIncome");
    else setNextLink("#");
  }, [amount, terms]);

  useEffect(() => {
    // console.log("yasmoo");
    dispatch(GetLoandRequest());
  }, []);
  useEffect(() => {
    setAmount(monto);
  }, [monto]);
  useEffect(() => {
    setTerms(terminos);
  }, [terminos]);
  return (
    <Box>
      {Location.pathname ===
      "/NonCustomerPage/yourLoanRequest/TermsAndConditions" ? (
        <Outlet />
      ) : (
        <Box>
          <Box className={classes.LoanContainer}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              width="100%"
            >
              <LoanDetails
                setAmount={setAmount}
                amount={amount}
                terms={terms}
                setTerms={setTerms}
                error={error}
                errorTerms={errorTerms}
              />
            </Stack>
            {amount || terms ? (
              <>
                {Number(amount) > 30000 && Number(terms) > 60 && (
                  <PersonalOnlineLoan message={'$30,000 and more than 60 months'} amount={amount} terms={terms} />
                )}

                {Number(amount) > 30000 && Number(terms) <= 60 && (
                  <PersonalOnlineLoan message={'$30,000'} amount={amount} terms={terms} />
                )}

                {Number(amount) <= 30000 && Number(terms) > 60 && (
                  <PersonalOnlineLoan message={'60 months'} amount={amount} terms={terms} />
                )}

                {Number(amount) <= 30000 && Number(terms) <= 60 && (
                  <LoanCalculator
                    amount={amount}
                    terms={terms}
                    setError={setError}
                    setErrorTerms={setErrorTerms}
                  />
                )}
              </>
            ) : (
              <LoanCalculator
                amount={amount}
                terms={terms}
                setError={setError}
                setErrorTerms={setErrorTerms}
              />
            )}
          </Box>

          {showButtonLink && (
            <ArrowButton link={nextLink} back="/NonCustomerPage/letsMeet" />
          )}
        </Box>
      )}
    </Box>
  );
};

export default YourLoanRequest;

import { Alert, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Cross from "../../assets/Images/Cross.png";
import { Box } from "@mui/system";
import React , {useState}from "react";
import { useStyles } from "./OtpPopUpStyle.js";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../../Store/Types";
import { Interceptor } from "../../Utils/Interceptor"
import LoadingButton from "@mui/lab/LoadingButton"

const PopupAlert = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const AxiosInterceptor = Interceptor()
  const Error = useSelector((state) => state.OtpValidation.codeError);
  const phone = useSelector((state) => state.LetsMeetFormReducer.phone);
  const [showLoading, setShowLoading] = useState(false)

  const sendData = async () => {
    const url = "/customers/confirmation/resend-code/phone"
    return await AxiosInterceptor.post(url,{phone : phone})
  }
  const handleReSend = async () => {
    setShowLoading(true)
   
   await sendData().then(()=> {
      setShowLoading(false)
      dispatch({
        type: Actions.OTPERROR,
        payload: false,
      })
    })
   
  }
  
  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={Error}
      aria-labelledby="draggable-dialog-title"
    >
      <Box className={classes.AlertBoxHeader}></Box>
      <Box className={classes.AlertMainSection}>
        <Box className={classes.HeaderLogoContainer}>
          <Box className={classes.HeaderLogoImage}>
            <img className={classes.CrossImage} src={Cross} alt="Cross" />
          </Box>
        </Box>
        <Typography className={classes.AlertText}>
          Invalid One Time Password
        </Typography>
        <DialogActions>
          <Button
            className={classes.AlertBtn}
            autoFocus
            onClick={() =>
              dispatch({
                type: Actions.OTPERROR,
                payload: false,
              })
            }
          >
            Re-Enter
          </Button>
          <LoadingButton
           className={classes.AlertBtn} 
           loading={showLoading}
           loadingPosition="end"
           onClick={handleReSend}
           autoFocus>
            Re-Send
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default PopupAlert;

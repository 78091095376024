import React, { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SmsText from '../../../assets/Images/sms-text.png'
import { InfoReferenceNumber, ModalForm, OTPInput, PopupError } from '../components'
import { setErrorState, verifyOTP } from '../store'
import { errorToastStyle, iconTheme } from '../options'
import { Box } from '@mui/material'
import { Errors, STATUS } from '../components/options.enum'


const Index = () => {
  const { error, OTP } = useSelector((state) => state.referenceNumber)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [returnInicialProcess, setReturnInicialProcess] = useState(false)
  const [closingStates, setClosingStates] = useState(null)

  const handleRedirectionByStatus = (status) => {
    const YourLoanRequest = [0]
    const LoanStatus = [1, 2, 5, 6, 7, 8, 12]
    const ScheduleAppointment = [3]

    if (YourLoanRequest.includes(status)) navigate('/NonCustomerPage/yourLoanRequest')
    if (LoanStatus.includes(status)) navigate('/Loan-Status')
    if (ScheduleAppointment.includes(status)) navigate('/NonCustomerPage/ScheduleAppointment')
  }

  const isThereError = (response) =>  response === "401" || response.error
  const handleError = (response) => {
      if (response.error.message === "401") {
        dispatch(setErrorState({ name: 'OTP', message: Errors.unauthorized}))
        setTimeout(() => {
          return navigate('/reference-number')
        }, 2500)
        return
      }
      if (response.error.message === "locked") {
        dispatch(setErrorState({ name: 'OTP', message: Errors.lockedOTP}))
        setReturnInicialProcess(true)
        return
      } else setReturnInicialProcess(false)

      if (response.error) return dispatch(setErrorState({ name: 'OTP', message: response.error.message}))
  }

  const onSubmit = () => {
    setClosingStates(null)
    if (String(OTP).length < 5) return toast.error('Please enter a OTP', {style: errorToastStyle, iconTheme})

    setLoading(true)

    dispatch(verifyOTP(OTP))
      .then((response) => {
        // console.log('response', response)
        if (isThereError(response)) {
          handleError(response)
          return
        }

        const token = response.payload.jwt
        const status = response.payload.status
        localStorage.setItem('access_token', token)
        if (status === 4) return setClosingStates(STATUS.DECLINED)
        if (status === 10) return setClosingStates(STATUS.FINALIZED)
        if (token) handleRedirectionByStatus(status)
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Toaster />
      <PopupError
        open={error.name === 'OTP'}
        message={error.message}
        onClick={() => {
          if(returnInicialProcess){
            navigate('/reference-number')
            dispatch(setErrorState({ name: null, message: null }))
          } else { dispatch(setErrorState({ name: null, message: null })) }
        } }
      />
      <ModalForm
        description={`Please enter the OTP sent to your mobile number.`}
        prevText={""}
        FormInput={OTPInput}
        loading={loading}
        buttonSubmitTitle={'Confirm'}
        onSubmit={onSubmit}
        logo={SmsText}
        // showBackButton={true}
      />
      {[4, 10].includes(closingStates) && (
        <Box>
          <InfoReferenceNumber status={closingStates} />
        </Box>
      )}
    </>
  )
}

export default Index
import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  UploadNOwBtn: {
    width: "365px",
    height: 42,
    "&.MuiButtonBase-root": {
      border: "1px solid #DADADA",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
        border: "1px solid #DADADA",
        "& .MuiTypography-root": {
          color: "white",
        },
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.secondary,
        color: "#B2B2B2",
        fontWeight: 500,
        letterSpacing: "0.2em",
      },
      "@media (max-width: 900px)": {
        width: "100%",
      },
    },
  },
  UploadNOwBtnTwo: {
    width: "365.23px",
    "&.MuiButtonBase-root": {
      height: 42,
      width: "100%",
      border: "1px solid #DADADA",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
        border: "1px solid #DADADA",
        "& .MuiTypography-root": {
          color: "white",
        },
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.secondary,
        color: "#B2B2B2",
        fontWeight: 500,
        letterSpacing: "0.2em",
      },
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
  },

  SecurityNumber: {
    width: "40%",
    "& .MuiInputBase-input": {
      width: "40%",
    },
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },

  LaterButton: {
    width: "365.23px",
    height: 42,
    filter: "",
    "&.MuiButtonBase-root": {
      width: "80%",
      marginTop: 23,
      backgroundColor: "White",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.secondary,
        color: "#B2B2B2",
        fontWeight: 500,
        letterSpacing: "0.1em",
      },
      "@media (max-width: 900px)": {
        width: "100%",
        marginTop: "0px",
      },
    },
  },
  ActiveButton: {
    backgroundColor: "#005FAA !important",
    "& .MuiTypography-root": {
      fontSize: 13,
      // backgroundColor: "#005FAA !important",
      color: "white !important",
      fontFamily: theme.fonts.family.secondary,
      fontWeight: 500,
      letterSpacing: "0.1em",
    },
  },
  UploadNowWrapper: {
    width: "100%",
  },
  LaterButtonTwo: {
    width: "365px",
    height: 42,
    "&.MuiButtonBase-root": {
      width: "80%",
      marginTop: "23px !important",
      // backgroundColor: theme.colors.primary.Darkblue,
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "&.MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.secondary,
        color: "White",
        fontWeight: 500,
        letterSpacing: "0.1em",
      },
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
  },
  Download: {
    width: "100%",
    height: 42,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    marginTop: "15px !important",
    "&.MuiButtonBase-root": {
      width: "100%",
      border: "1px solid #DADADA",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
        borderColor: "#DADADA",
        color: "white",
        "& .MuiTypography-root": {
          color: "white",
        },
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.secondary,
        color: "#B2B2B2",
        fontWeight: 500,
        letterSpacing: "0.1em",
      },
      "@media (max-width: 600px)": {
        marginBottom: "10px",
        marginTop: "0px",
        height: "auto",
      },
    },
  },
  Laterbuttontwo: {
    display: "none",
    "&.MuiButtonBase-root": {
      // marginTop: 6,
    },
  },
  NextButton: {
    width: "20%",
    height: 39.01,
    "&.MuiButtonBase-root": {
      backgroundColor: theme.colors.primary.Darkblue,
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        color: "White",
        fontWeight: 600,
        letterSpacing: "0.2em",
      },
    },
  },
  Lable: {
    "&.MuiFormLabel-root": {
      margin: "8px 0px 0px -10px",
    },
  },
  FormTitle: {
    "&.MuiTypography-root": {
      margin: "3.5rem 0rem 1rem 0rem",
      fontFamily: theme.fonts.family.secondary,
      fontSize: 16,
      fontWeight: 800,
      color: theme.colors.primary.Darkblue,
      letterSpacing: "0.1em",
    },
  },
  SubTitle: {
    "&.MuiTypography-root": {
      fontFamily: theme.fonts.family.secondary,
      color: "red",
      marginBottom: "1rem"
    },
  },

  SecurityNumber: {
    width: "100%",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  fileText: {
    fontFamily: theme.fonts.family.secondary,
    fontSize: 16,
    fontWeight: 400,
    color: theme.colors.secondary.Green,
    letterSpacing: "0.1em",
    textAlign: "center",
  },
  fileTextErr: {
    fontFamily: theme.fonts.family.secondary,
    fontSize: 13,
    fontWeight: 400,
    color: "red",
    letterSpacing: "0.1em",
    textAlign: "center",
  },
  SelectAddres: {
    "@media (min-width: 600px)": {
      marginTop: "44px !important",
    },
  },
    deleteFileIcon: {
    "&.MuiSvgIcon-root": {
      color:" red",
      marginTop: 0,
      fontSize: "large",
      marginLeft: 10,
      position: "absolute"
    },
  },
}))

import Actions from "../Types"

const initialState = {
  requestedAmount: "", // number
  requestedTerms: "", // number
  debtCancellationSchemeRequested: null,
  debtCancellationSchemeFee: "", // number
  fees: "", // number
  totalAmount: 0,
  monthlyInstallment: "", // number
  promoCode: "",
  monthlyInstallmentWithPromoCode: 0,
  createdDate: "",
  customerId: "",
  interestRate: 0,
  interestRateWithPromoCode: 0,
  referenceStatus: false,
  amount: 0,
  terms: 0,
  totalDebtCancellationSchemeFee: 0,
  isPromoValid: null,
}
const returnToInitialState = () => {
  return {
    requestedAmount: "", // number
    requestedTerms: "", // number
    debtCancellationSchemeRequested: null,
    debtCancellationSchemeFee: "", // number
    fees: "", // number
    totalAmount: 0,
    monthlyInstallment: "", // number
    promoCode: "",
    monthlyInstallmentWithPromoCode: 0,
    createdDate: "",
    customerId: "",
    interestRate: 0,
    interestRateWithPromoCode: 0,
    referenceStatus: false,
    amount: 0,
    terms: 0,
    totalDebtCancellationSchemeFee: 0,
    isPromoValid: null,
  }

}

const LoanRequestReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.LOANREQUEST:
      return {
        ...state,
        fees: payload.fees,
        monthlyInstallment: payload.monthlyInstallment,
        debtCancellationSchemeFee: payload.debtCancellationSchemeFee,
        requestedAmount: payload.requestedAmount,
        requestedTerms: payload.requestedTerms,
        referenceStatus: payload.referenceStatus,
        promoCode : payload.promoCode,
        totalDebtCancellationSchemeFee : payload.totalDebtCancellationSchemeFee,
        monthlyInstallmentWithPromoCode : payload.monthlyInstallmentWithPromoCode
      }

    case Actions.SETLOADNREQUEST:
      return {
        ...state,
        ...payload,
      }
    case Actions.SETAMOUNTDETAILS:
      return {
        ...state,
        amount: payload,
      }
    case Actions.SETTERMSTDETAILS:
      return {
        ...state,
        terms: payload,
      }
    case Actions.SETMONTHLYINSTALLMENTWITHPROMOCODE:
    return {
      ...state,
      monthlyInstallmentWithPromoCode: payload
    }
    case Actions.SETISVALIDPROMOCODE:
      return {
        ...state,
        isPromoValid:  {...payload}
      }
    case Actions.CLEARSTATE :
        return returnToInitialState()
    default:
      return state
  }
}

export default LoanRequestReducer

import React from "react";
import { Typography } from "@mui/material";
import Success from "../../assets/Images/Success.png";
import { Box, Stack } from "@mui/system";
import { useStyles } from "./OtpPopUpStyle.js";
import { useSelector } from "react-redux";

const PopupSuccess = () => {
  const classes = useStyles();

  const referenceNumber = useSelector(
    (state) => state.OtpValidation.referenceNumber
  );

  return (
    <Stack>
      <Box className={classes.SuccessBoxContainer}>
        <Box className={classes.SuccessBoxHeader}></Box>
        <Box className={classes.SuccessMainSection}>
          <Box className={classes.SuccessLogoContainer}>
            <Box className={classes.SuccessLogoImage}>
              <img className={classes.CrossImage} src={Success} alt="Cross" />
            </Box>
          </Box>
          <Typography className={classes.SuccessText}>
            The code is correct!
          </Typography>
        </Box>
      </Box>
      <Box className={classes.SuccessDiscription}>
        <Typography className={classes.SuccessDiscriptionText}>
          Your online loan application reference number is{" "}
          <strong>"{referenceNumber}"</strong>
          <br />
          This number is valid for 3 months. Please securely save a copy of it
          for future use.
        </Typography>
      </Box>
    </Stack>
  );
};

export default PopupSuccess;

const LocalStorageService = (function () {
  let _service
  function _getService() {
    if (!_service) {
      _service = this
      return _service
    }
    return _service
  }

  function _setToken(token) {
    localStorage.setItem("access_token", token)
  }
  function _setExpirationDate(date) {
    localStorage.setItem("expirationDate", date)
  }
  function _getExpirationDate() {
    return localStorage.getItem("expirationDate")
  }
  function _setReferenceStatus(status) {
    return localStorage.setItem("referenceStatus", status)
  }
  function _getReferenceStatus() {
    return localStorage.getItem("referenceStatus")
  }
  function _getAccessToken() {
    return localStorage.getItem("access_token")
  }
  function _clearToken() {
    localStorage.removeItem("access_token")
    localStorage.removeItem("user_type")
    localStorage.removeItem("expirationDate")
    localStorage.removeItem("referenceStatus")
    localStorage.removeItem("customer_schedule")
    localStorage.removeItem("customer_data")
  }

  return {
    getService: _getService,
    setToken: _setToken,
    getExpirationDate: _getExpirationDate,
    setExpirationDate: _setExpirationDate,
    setReferenceStatus: _setReferenceStatus,
    getReferenceStatus: _getReferenceStatus,
    getAccessToken: _getAccessToken,
    clearToken: _clearToken,
  }
})()

export default LocalStorageService

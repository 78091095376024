import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  MessageContainer: {
    width: "90%",
    margin: "auto",
  },

  MessageListSectionContainer: {
    width: "100%",
    // height: "60vh",
    // overflow: "auto",
  },
  deleteFileIcon: {
    "&.MuiSvgIcon-root": {
      color:" red",
      marginTop: 0,
      fontSize: "large",
      marginLeft: 10,
      position: "absolute"
    },
  },
  uploadFileName: {
    fontFamily: theme.fonts.family.secondary,
    fontSize: 15,
    fontWeight: 400,
    color: theme.colors.secondary.Green,
    width:500,
    marginTop:0,
    marginBottom:15,
    marginTop: 20
  },
  MessageHeading: {
    "&.MuiTypography-root": {
      color: "#005FAA",
      fontWeight: "800",
      fontSize: "35px",
      lineHeight: "47.81px",
      marginBottom: "16px",
      marginTop: "16px",
      fontFamily: theme.fonts.family.secondary,
    },
  },
  MessageListSection: {
    width: "97%",
    // minHeight: "790px",
    border: "1px solid #DADADA",
    height: "60vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0.8em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      outline: "1px solid #DADADA",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#C6C6C6",
      outline: "1px solid #DADADA",
      borderRadius: 12,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#DADADA",
    },
  },
  SubmitDate: {
    background: "#EDEDED",
    minHeight: "42px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#706F6F",
    fontFamily: theme.fonts.family.secondary,
    fontSize: "21px",
    fontweight: 500,
    lineHeight: "29px",
  },
  MessageBox: {
    width: "70%",
    display: "flex",
    alignItems: "flex-end",
    marginRight: "20px",
    marginBottom: "20px",
  },
  MessageList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 0px 20px 40px",
    background: "#FEF1E7",
    borderRadius: "10px",
    padding: "30px",
    "& .MuiTypography-root": {
      width: "100%",
      letterSpacing: "0.1em",
      fontSize: "20px",
      lineHeight: "29px",
      fontFamily: theme.fonts.family.secondary,
      fontWeight: "300",
      color: "#706F6F",
    },
  },
  MessageListTwo: {
    background: "#EAF2FB",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 0px 20px 40px",
    borderRadius: "10px",
    padding: "30px",
    "& .MuiTypography-root": {
      width: "100%",
      letterSpacing: "0.1em",
      fontSize: "20px",
      lineHeight: "29px",
      fontFamily: theme.fonts.family.secondary,
      fontWeight: "300",
      color: "#706F6F",
    },
  },
  MessageDate: {
    "&.MuiTypography-root": {
      width: "70px",
      fontSize: "13px",
      color: "#706F6F",
      lineHeight: "17.76px",
      fontFamily: theme.fonts.family.secondary,
    },
  },
  MenuContainer: {
    width: "98%",
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
  },
  MenuIcon: {
    width: "60.16px",
    height: "41px",
    border: "1px solid #DADADA",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    display: "none",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      position: "absolute",
      width: "30px",
      height: "30px",
      color: "#005FAA",
    },
  },
  Menu: {
    "& .MuiPopover-paper": {
      width: "235px",
      filter: "dropShadow(3px 3px 2px rgba(157, 157, 156, 0.251))",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "10px",
    },
  },
  SubmitInput: {
    "& .MuiInputBase-root": {
      marginTop: 0,
      marginLeft: "14px",
    },
  },
  MessageInput: {
    "& .MuiOutlinedInput-input": {
      paddingLeft: "10px",
    },
    "& .MuiOutlinedInput-root": {
      paddingLeft: "10px",
      minHeight: "42px",
      padding: "unset",
    },
    "& .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#DADADA",
    },
  },

  Icons: {
    width: "36px",
    height: "36px",
  },
  MenuName: {
    "&.MuiTypography-root": {
      fontSize: "20px",
      color: "#706F6F",
      lineHeight: "27.32px",
    },
  },

  SubmitBtn: {
    "&.MuiButton-root": {
      marginLeft: "34px",
      width: "133.27px",
      height: "38px",
      background: "#F07F3C",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
    },
    "& .MuiTypography-root": {
      fontSize: "13px",
      color: "#ffff",
      fontWeight: "600",

      lineHeight: "15.57px",
      letterSpacing: "0.2em",
      fontFamily: theme.fonts.family.primary,
    },
  },
  GoBackBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "26px",
    marginBottom: "26px",

    "& .MuiButton-root": {
      marginLeft: "34px",
      width: "360px",
      minHeight: "39px",
      background: "#005FAA",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
    },
    "& .MuiTypography-root": {
      fontWeight: "600",
      fontSize: "13px",
      color: "#ffff",
      lineHeight: "15.57px",
      letterSpacing: "0.2em",
      fontFamily: theme.fonts.family.primary,
    },
  },
  AttachFile: {
    border: 'solid 1px  #DADADA',
    padding: '5px',
    display: 'flex'

  },
  UploadFiles : {
    color: 'black'
  }
}));

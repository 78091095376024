import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

  MainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ["@media (max-width: 1000px)"]: {
      width: "100%",
    },
    ["@media (max-width: 500px)"]: {
      width: "100%",
    },
  },

  CardContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "1.1rem",
    position: "relative",
    ["@media (max-width: 1000px)"]: {
      width: "100%",
    },
    ["@media (max-width: 500px)"]: {
      width: "90%",
      margin: "1.1rem 1.8rem",
    },
  },
  IconWrapper: {
    position: "absolute",
    top: -60,
  },
  Wrapper: {
    padding: "4rem 6.5rem",
    ["@media (max-width: 1000px)"]: {
      padding: "3rem 3rem",
    },
    padding: "4rem 6.5rem",
    ["@media (max-width: 500px)"]: {
      padding: "3rem 1rem",
    },
  },
  Smile: {
    width: "6.95rem",
    height: "6.95rem",
  },

  Text: {
    position: "relative",
    top: "70px",
    textAlign: "center",
    fontFamily: theme.fonts.family.secondary,
    fontWeight: "800",
    fontSize: "1.1rem",
    lineHeight: "1.5rem",
  },

  stackButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: "3.5rem",
    alignItems: "center",
    gap: "0.8rem",
    ["@media (max-width: 1000px)"]: {
      width: "100%",
      flexWrap: "wrap",
    },
    ["@media (max-width: 500px)"]: {
      width: "100%",
      flexWrap: "wrap",
    },
  },

  Button: {
    position: "relative",
    top: "40px",
    width: "18.13rem",
    height: "1.95rem",
    "&.MuiButtonBase-root": {
      borderRadius: 7,
      backgroundColor: theme.colors.primary.Darkblue,
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .css-10wpov9-MuiTypography-root": {
        fontFamily: theme.fonts.family.primary,
        fontSize: "0.81rem",
        fontWeight: "600",
        letterSpacing: "0.2em",
      },
    },
  },


  Links: {
    textDecoration: "none",
  },

  Lable: {
    "&.MuiFormLabel-root": {
      margin: "8px 0px 0px -10px",
    },
  },

  Input: {
    width: "80%",
  },
  
  Form: {
    marginTop: 75
  },

  Select: {

    marginTop: 24,
    height: 42,
    width: '70%',
    backgroundColor: "#FFFFFF",
    borderColor: "red",
    "&.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#DADADA",
      },
    "& .MuiSelect-select": {
      "& em": {
        fontFamily: theme.fonts.family.tertiary,
        fontSize: 13,
        fontWeight: 500,
        fontStyle: "normal",
        color: "#B2B2B2",
      },
    },
  },
  Dropdown: {
    "&.MuiPaper-root": {
      backgroundColor: "#616164",
      "& .MuiMenuItem-root": {
        fontFamily: theme.fonts.family.secondary,
        fontWeight: 400,
        fontSize: 13,
        margin: "0px 10px",
        color: "white",
        "&:first-child": {
          display: "none",
        },
        "&:hover": {
          backgroundColor: "#679CE5",
          borderRadius: 8,
        },
      },
    },
  },
  
  placeholder: {
    color: "#aaa"
  }

}));
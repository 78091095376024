import { baseUrl } from '../../baseUrl'



export const endpoints = {
  customer: `${baseUrl}/customer`,
  customers: `${baseUrl}/customers`,
  assets: `${baseUrl}/assets`,
  assetsByCustomer: `${baseUrl}/customer-assets`,


  tokenKeyName: 'access_token',
  tokenType: 'Bearer',
  storageRefreshTokenKeyName: 'refreshToken',
}
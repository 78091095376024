import axios from "axios";
// import { baseUrl } from "../baseUrl";
// import LocalStorageService from "./LocalStoreServices";
import { endpoints } from './endpoints';
import toast from 'react-hot-toast'

export default class JwtService {
  #jwtConfig = { ...endpoints }

  #isAlreadyFetchingAccessToken = false

  #subscribers = []
  constructor(jwtOverrideConfig = {}) {
    this.#jwtConfig = { ...this.#jwtConfig, ...jwtOverrideConfig }

    axios.interceptors.request.use(
      (config) => {
        const accessToken = this.#getToken()

        if (accessToken?.length > 0) {
          config.headers.Authorization = `${this.#jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          // toast.error('Your session has expired! Please log in again.', {
          //   style: { borderRadius: '10px', background: '#333', color: '#fff' },
          //   iconTheme: { primary: '#fff', secondary: '#333' }
          // })
          if (!this.#isAlreadyFetchingAccessToken) {
            this.#isAlreadyFetchingAccessToken = true
            this.getRefreshToken().then((r) => {
              this.#isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          return new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${this.#jwtConfig.tokenType} ${accessToken}`
              resolve(this.axios(originalRequest))
            })
          })
        }
        return Promise.reject(error)
      }
    )
  }

  #getToken() {
    try {
    return localStorage?.getItem(this.#jwtConfig.tokenKeyName)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  onAccessTokenFetched(accessToken) {
    this.#subscribers = this.#subscribers.filter((callback) => callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.#subscribers.push(callback)
  }

  getRefreshToken() {
    return localStorage.getItem(this.#jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.#jwtConfig.tokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.#jwtConfig.storageRefreshTokenKeyName, value)
  }

  #simulationHTTP(payload) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({data: {...payload}})
      }, 2500)
    })
  }

  #simulationHTTPErrorNotFound(payload) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject({data: {...payload}})
      }, 2500)
    })
  }

  verifyReferenceNumber(referenceNumber) {
    return axios.get(`${this.#jwtConfig.customers}/referenceNumberAuthentication/${referenceNumber}`)
  }

  verifyPhoneNumber(phoneNumber) {
    return axios.post(`${this.#jwtConfig.customer}/referenceNumberValidator`, { phoneNumber }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('referenceNumber_jwt')}`
      }
    })
  }

  validateConfirmationCode(confirmationCode) {
    return axios.post(`${this.#jwtConfig.customers}/validateConfirmationCode`, { confirmationCode }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('phoneNumber_jwt')}`
      }
    })
  }

  getAssets() {
    return axios.get(this.#jwtConfig.assets)
  }

  getAssetsByCustomer () {
    return axios.get(this.#jwtConfig.assetsByCustomer)
  }

  saveAssetByCustomer(data) {
    return axios.post(this.#jwtConfig.assetsByCustomer, data)
  }

  deleteAssetByCustomer(id) {
    return axios.delete(`${this.#jwtConfig.assetsByCustomer}/${id}`)
}

updateAssetByCustomer(data) {
  return axios.patch(this.#jwtConfig.assetsByCustomer, data)
}
}
// export default JwtService;

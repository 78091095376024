import React, { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import VerificationInput from "../Inputs/VerificationInput";
import Image from "../../assets/Images/Group31.png";
import clock from "../../assets/Images/clock.png";
import { useStyles } from "./OtpPopUpStyle.js";
import Button from "@mui/material/Button";
import { otpValidation } from "../../Store/Actions/OtpValidation";
import { useDispatch, useSelector } from "react-redux";
import PopupAlert from "./PopupAlert";
import PopupSuccess from "./PopupSuccess";
import ArrowButton from "../ArrowButton/ArrowButton";

export const ConfirmOTP = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [pin, setPin] = useState("");
  const classes = useStyles();

  const phone = useSelector((state) => state.LetsMeetFormReducer.phone);
  const otpSuccess = useSelector((state) => state.OtpValidation.otpSuccess);

  const getOtp = () => {
    dispatch(otpValidation({ phone: phone, code: pin }));
  };

  return (
    <Box>
      <PopupAlert />
      <Box className={classes.PopupContainer}>
        <Box className={classes.PopupHeader}></Box>

        <Box className={classes.PopupMainSection}>
          <Box className={classes.PopupHeaderLogo}>
            <img className={classes.Logo} src={Image} alt="Logo" />
          </Box>
          <Typography className={classes.PopupText}>
            To communicate with you effectively, we will need to validate your
            mobile number. An SMS has been sent to the number provided for this
            application. Please enter the activation code here:
          </Typography>
          <VerificationInput
            length={5}
            onChange={(e) => setPin(e)}
            sx={{
              borderRadius: "8px",
              fontSize: "16px",
            }}
          />
          <Box className={classes.PopupExpireTime}>
            <img className={classes.ClockImage} src={clock} alt="clock" />
            <Typography className={classes.ExpireTimeText}>
              The code will expire in 15 minutes
            </Typography>
          </Box>
          <Box className={classes.PopupBtn}>
            <Button
              className={classes.PopupButton}
              onClick={getOtp}
              sx={{ fontSize: "13px" }}
            >
              VALIDATE CODE
            </Button>
          </Box>
        </Box>
      </Box>
      {otpSuccess && (
        <Box>
          <PopupSuccess />
          <ArrowButton
            link="/NonCustomerPage/yourLoanRequest"
            back="/NonCustomerPage/letsMeet"
          />
        </Box>
      )}
    </Box>
  );
};

const ForgetPassword = ({
  phoneNumber: lockPhoneNumber,
  onSuccessfulLogin,
  goBackUrl,
}) => {
  const theme = useTheme();
  const [sentCode, setSentCode] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");

  const changePassword = async (pin) => {};

  const sendCode = async (resend, password) => {};

  return (
    <>
      <ConfirmOTP
        sendCode={sendCode}
        onSuccessfulLogin={onSuccessfulLogin}
        cancel={() => setSentCode(false)}
        phoneNumber={phoneNumber}
        submit={changePassword}
      />
    </>
  );
};

export default ForgetPassword;

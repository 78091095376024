import LetsMeetForm from "../Components/Forms/LetsMeetForm/LetsMeetForm"
import YourLoanRequest from "../Components/Forms/YourLoanRequest/YourLoanRequest"
import TermsAndConditions from "../Components/TermsAndConditions/TermsAndConditions"
import MonthlyIncome from "../Components/Forms/MonthlyIncome/MonthlyIncome"
import MonthlyCreditObligations from "../Components/Forms/MonthlyCreditObligations/MonthlyCreditObligations"
import LoanPurpose from "../Components/Forms/LoanPurpose/LoanPurpose"
import ProofOfIncome from "../Components/Forms/ProofOfIncome/ProofOfIncome"
import OtpPopUp from "../Components/OtpPopup/OtpPopUp"
import LoanStatus from "../Components/Forms/LoanStatus/LoanStatus"
import ScheduleAppointment from "../Components/Forms/ScheduleAppointment/ScheduleAppointment"
import MakeAppointment from "../Components/Forms/ScheduleAppointment/MakeAppointment"
import ScheduleApproved from "../Components/Forms/ScheduleAppointment/ScheduleApproved"
import ScheduleThankYou from "../Components/Forms/ScheduleAppointment/SheduleThankYou"
import Status from "../Components/Forms/LoanStatus/Status"
import AssetsDeclaration from "../Components/Forms/AssetsDeclaration/"

const PAGES = [
  {
    localeKey: "Lets meet form",
    key: "/letsMeet",
    Component: LetsMeetForm,
    letsmeetpages: [
      {
        localeKey: "OTP",
        key: "/opt",
        Component: OtpPopUp,
        // Protected: true,
      },
    ],
  },
  {
    localeKey: "Your load request",
    key: "/yourLoanRequest",
    Component: YourLoanRequest,
    Protected: true,
    yourLoanRequestpages: [
      {
        localeKey: "Terms and conditions",
        key: "/TermsAndConditions",
        Component: TermsAndConditions,
        Protected: true,
      },
    ],
  },
  {
    localeKey: "Monthly income",
    key: "/monthlyIncome",
    Component: MonthlyIncome,
    Protected: true,
  },
  {
    localeKey: "Monthly credit obligations",
    key: "/MonthlyCreditObligations",
    Component: MonthlyCreditObligations,
    Protected: true,
  },
  {
    localeKey: "Loan Purpose",
    key: "/LoanPurpose",
    Component: LoanPurpose,
    Protected: true,
  },
  {
    localeKey: "Proof Of Income",
    key: "/ProofOfIncome",
    Component: ProofOfIncome,
    Protected: true,
  },
  {
    localeKey: "Assets Declaration",
    key: "/AssetsDeclaration",
    Component: AssetsDeclaration,
    Protected: true,
  },
  {
    localeKey: "Schedule Appointment",
    key: "/ScheduleAppointment",
    Component: ScheduleAppointment,
    Protected: true,
  },
  {
    localeKey: "Make Appointment Form",
    key: "/ScheduleAppointment/MakeAppointment",
    Component: ScheduleAppointment,
    Protected: true,
  },
  {
    localeKey: "Schedule Appointment Approved",
    key: "ScheduleAppointment/ScheduleApproved",
    Component: ScheduleApproved,
    Protected: true,
  },
  {
    localeKey: "Schedule Appointment ThankYou",
    key: "ScheduleAppointment/ScheduleThankYou",
    Component: ScheduleThankYou,
    Protected: true,
  },
  {
    localeKey: "Loan Status",
    key: "/LoanStatus",
    Component: LoanStatus,
    Protected: true,
  },
]

export default PAGES
